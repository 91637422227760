import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "./services/supabase";
import { getUserInfo } from "./utils/userInfo";
import { hapticFeedback, initTelegram } from "./utils/telegram";
import { CandidateSelection } from "./components/CandidateSelection";
import { VotingInterface } from "./components/VotingInterface";
import {
  SHOP_ITEMS,
  calculateTotalClicksPerSecond,
  calculateNextCost,
} from "./services/shop";

function App() {
  // Основные состояния
  const [scores, setScores] = useState({
    candidate1: 0,
    candidate2: 0,
  });

  const [localScores, setLocalScores] = useState(() => {
    const stored = localStorage.getItem("localScores");
    return stored
      ? JSON.parse(stored)
      : {
          candidate1: 0,
          candidate2: 0,
        };
  });

  const [unsavedVotes, setUnsavedVotes] = useState({
    candidate1: 0,
    candidate2: 0,
  });

  const [userId] = useState(() => {
    const stored = localStorage.getItem("userId");
    if (stored) return stored;
    const newId = crypto.randomUUID();
    localStorage.setItem("userId", newId);
    return newId;
  });

  const [sessionId] = useState(() => crypto.randomUUID());
  const [error, setError] = useState(null);
  const [isPressed, setIsPressed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(() => {
    return localStorage.getItem("selectedCandidate");
  });

  const [ownedItems, setOwnedItems] = useState(() => {
    const stored = localStorage.getItem("ownedItems");
    return stored ? JSON.parse(stored) : {};
  });

  const [totalClicksPerSecond, setTotalClicksPerSecond] = useState(0);

  const handleShareVotes = async (candidate) => {
    const votesToShare = Math.floor(localScores[candidate] || 0);

    if (!votesToShare) return;

    try {
      setIsLoading(true);

      // Отправляем накопленные голоса в базу
      const { error: updateError } = await supabase
        .from("total_scores")
        .update({
          total_points: scores[candidate] + votesToShare,
          updated_at: new Date().toISOString(),
        })
        .eq("candidate_id", candidate);

      if (updateError) throw updateError;

      // Логируем отправку голосов
      const { error: logError } = await supabase.from("clicks_log").insert({
        user_id: userId,
        candidate_id: candidate,
        points: votesToShare,
        session_id: sessionId,
        click_metadata: {
          timestamp: new Date().toISOString(),
          type: "batch_share",
          windowState: {
            focused: document.hasFocus(),
            visible: document.visibilityState,
            online: navigator.onLine,
          },
        },
      });

      if (logError) throw logError;

      // Обновляем общий счет
      setScores((prev) => ({
        ...prev,
        [candidate]: prev[candidate] + votesToShare,
      }));

      // Сбрасываем локальный счет
      setLocalScores((prev) => {
        const updated = {
          ...prev,
          [candidate]: 0,
        };
        localStorage.setItem("localScores", JSON.stringify(updated));
        return updated;
      });

      // Добавляем маленькую тактильную отдачу
      hapticFeedback("success");
    } catch (err) {
      console.error("Share votes error:", err);
      setError(err.message);
      hapticFeedback("error");
    } finally {
      setIsLoading(false);
    }
  };

  // Инициализация Telegram
  useEffect(() => {
    initTelegram();
  }, []);

  // Фоновая синхронизация с сервером
  useEffect(() => {
    const syncInterval = setInterval(async () => {
      try {
        // Проверяем, есть ли что сохранять
        if (unsavedVotes.candidate1 === 0 && unsavedVotes.candidate2 === 0) {
          return;
        }

        const votesToSync = { ...unsavedVotes };

        // Для каждого кандидата с ненулевыми голосами делаем обновление
        for (const candidateId of ["candidate1", "candidate2"]) {
          if (votesToSync[candidateId] > 0) {
            const { error } = await supabase
              .from("total_scores")
              .update({
                total_points: scores[candidateId] + votesToSync[candidateId],
                updated_at: new Date().toISOString(),
              })
              .eq("candidate_id", candidateId);

            if (error) throw error;

            // Логируем клики
            const { error: logError } = await supabase
              .from("clicks_log")
              .insert({
                user_id: userId,
                candidate_id: candidateId,
                points: votesToSync[candidateId],
                session_id: sessionId,
                click_metadata: {
                  timestamp: new Date().toISOString(),
                  windowState: {
                    focused: document.hasFocus(),
                    visible: document.visibilityState,
                    online: navigator.onLine,
                  },
                },
              });

            if (logError) throw logError;
          }
        }

        // После успешной синхронизации обновляем локальные счетчики
        setScores((prev) => ({
          candidate1: prev.candidate1 + votesToSync.candidate1,
          candidate2: prev.candidate2 + votesToSync.candidate2,
        }));

        // Сбрасываем несохраненные голоса
        setUnsavedVotes({
          candidate1: 0,
          candidate2: 0,
        });
      } catch (err) {
        console.error("Sync error:", err);
        setError(err.message);
      }
    }, 5000);

    return () => clearInterval(syncInterval);
  }, [unsavedVotes, scores, userId, sessionId]);

  // Загрузка начальных данных с сервера
  useEffect(() => {
    async function loadScores() {
      try {
        const { data, error } = await supabase.from("total_scores").select("*");
        if (error) throw error;

        setScores({
          candidate1:
            data.find((s) => s.candidate_id === "candidate1")?.total_points ||
            0,
          candidate2:
            data.find((s) => s.candidate_id === "candidate2")?.total_points ||
            0,
        });
      } catch (err) {
        console.error("Load error:", err);
        setError(err.message);
      }
    }

    loadScores();
    const interval = setInterval(loadScores, 5000);
    return () => clearInterval(interval);
  }, []);

  // Эффект для автоматических кликов
  useEffect(() => {
    const cps = calculateTotalClicksPerSecond(ownedItems);
    setTotalClicksPerSecond(cps);

    if (cps > 0 && selectedCandidate) {
      const ticksPerSecond = 10; // 10 обновлений в секунду
      const clicksPerTick = cps / ticksPerSecond;

      const interval = setInterval(() => {
        // Обновляем локальный счет
        setLocalScores((prev) => {
          const updated = {
            ...prev,
            [selectedCandidate]: prev[selectedCandidate] + clicksPerTick,
          };
          localStorage.setItem("localScores", JSON.stringify(updated));
          return updated;
        });

        // Добавляем в несохраненные голоса
        setUnsavedVotes((prev) => ({
          ...prev,
          [selectedCandidate]: prev[selectedCandidate] + clicksPerTick,
        }));
      }, 1000 / ticksPerSecond); // интервал в миллисекундах (100мс)

      return () => clearInterval(interval);
    }
  }, [ownedItems, selectedCandidate]);

  // Инициализация пользователя
  useEffect(() => {
    async function initUser() {
      try {
        const userInfo = getUserInfo();

        const { error } = await supabase.from("users").upsert(
          {
            id: userId,
            ...userInfo,
            last_seen_at: new Date().toISOString(),
          },
          {
            onConflict: "id",
            returning: "minimal",
          }
        );

        if (error) throw error;
      } catch (err) {
        console.error("Failed to init user:", err);
        setError(err.message);
      }
    }

    if (userId) {
      initUser();
    }
  }, [userId]);

  const handleClick = useCallback(() => {
    if (!selectedCandidate || isLoading) return;

    hapticFeedback("medium");
    setIsPressed(true);

    // Обновляем локальный счет
    setLocalScores((prev) => {
      const updated = {
        ...prev,
        [selectedCandidate]: prev[selectedCandidate] + 1,
      };
      localStorage.setItem("localScores", JSON.stringify(updated));
      return updated;
    });

    // Добавляем в несохраненные голоса
    setUnsavedVotes((prev) => ({
      ...prev,
      [selectedCandidate]: prev[selectedCandidate] + 1,
    }));

    setTimeout(() => {
      setIsPressed(false);
    }, 100);
  }, [selectedCandidate, isLoading]);

  const handleCandidateSelect = (candidate) => {
    hapticFeedback("selectionChanged");
    setSelectedCandidate(candidate);
    localStorage.setItem("selectedCandidate", candidate);

    // Сбрасываем локальный счет для нового кандидата
    setLocalScores((prev) => {
      const updated = {
        ...prev,
        [candidate]: 0,
      };
      localStorage.setItem("localScores", JSON.stringify(updated));
      return updated;
    });
  };

  const handlePurchase = (itemId) => {
    const item = SHOP_ITEMS[itemId];
    const owned = ownedItems[itemId] || 0;
    const cost = calculateNextCost(item.baseCost, owned);

    if (localScores[selectedCandidate] >= cost) {
      setLocalScores((prev) => {
        const updated = {
          ...prev,
          [selectedCandidate]: prev[selectedCandidate] - cost,
        };
        localStorage.setItem("localScores", JSON.stringify(updated));
        return updated;
      });

      setOwnedItems((prev) => {
        const updated = {
          ...prev,
          [itemId]: (prev[itemId] || 0) + 1,
        };
        localStorage.setItem("ownedItems", JSON.stringify(updated));
        return updated;
      });
    }
  };

  if (!selectedCandidate) {
    return <CandidateSelection onSelect={handleCandidateSelect} />;
  }

  return (
    <VotingInterface
      scores={scores}
      localScores={localScores}
      error={error}
      handleClick={handleClick}
      isPressed={isPressed}
      isLoading={isLoading}
      selectedCandidate={selectedCandidate}
      handleCandidateSelect={handleCandidateSelect}
      ownedItems={ownedItems}
      handlePurchase={handlePurchase}
      totalClicksPerSecond={totalClicksPerSecond}
      handleShareVotes={handleShareVotes}
    />
  );
}

export default App;
