import React from "react";
import styled from "styled-components";
import { COLORS } from "../styles/StyledComponents";
import { SHOP_ITEMS, calculateNextCost, canAffordItem } from "../services/shop";

// Styled Components для магазина
const ShopContainer = styled.div`
  margin-top: 20px;
`;

const ShopTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: ${COLORS.text};
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
`;

const ShopItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border: 2px solid ${COLORS.border};
  background: ${(props) => (props.$canAfford ? "#ffffff" : "#f5f5f5")};
  opacity: ${(props) => (props.$canAfford ? 1 : 0.7)};
  cursor: ${(props) => (props.$canAfford ? "pointer" : "not-allowed")};
  transition: all 0.2s;

  &:hover {
    background: ${(props) => (props.$canAfford ? "#f0f0f0" : "#f5f5f5")};
    transform: ${(props) => (props.$canAfford ? "translateY(-2px)" : "none")};
  }
`;

const ItemIcon = styled.div`
  font-size: 24px;
  margin-right: 15px;
  width: 40px;
  text-align: center;
`;

const ItemInfo = styled.div`
  flex: 1;
`;

const ItemName = styled.div`
  font-weight: 600;
  color: ${COLORS.text};
`;

const ItemDescription = styled.div`
  font-size: 14px;
  color: #666;
`;

const ItemStats = styled.div`
  text-align: right;
  color: ${COLORS.text};
`;

const ItemCost = styled.div`
  font-weight: 600;
  color: ${(props) => (props.$canAfford ? COLORS.text : "#999")};
`;

const ItemOwned = styled.div`
  font-size: 14px;
  color: #666;
`;

export const Shop = ({
  party,
  currentPoints,
  ownedItems,
  onPurchase,
  totalClicksPerSecond,
}) => {
  return (
    <ShopContainer>
      <ShopTitle>Buy Campaign Upgrades:</ShopTitle>

      <ItemsContainer>
        {Object.values(SHOP_ITEMS).map((item) => {
          const owned = ownedItems[item.id] || 0;
          const nextCost = calculateNextCost(item.baseCost, owned);
          const affordable = canAffordItem(item.id, ownedItems, currentPoints);

          return (
            <ShopItem
              key={item.id}
              $canAfford={affordable}
              onClick={() => affordable && onPurchase(item.id)}
            >
              <ItemIcon>{item.icon}</ItemIcon>
              <ItemInfo>
                <ItemName>{item.name}</ItemName>
                <ItemDescription>{item.description}</ItemDescription>
              </ItemInfo>
              <ItemStats>
                <ItemCost $canAfford={affordable}>
                  {nextCost.toLocaleString()} votes
                </ItemCost>
                <ItemOwned>Owned: {owned}</ItemOwned>
              </ItemStats>
            </ShopItem>
          );
        })}
      </ItemsContainer>
    </ShopContainer>
  );
};

export default Shop;
