import React from "react";
import { Container, Title, CandidateButton } from "../styles/StyledComponents";

export const CandidateSelection = ({ onSelect }) => {
  return (
    <Container>
      <Title>Select Your Candidate</Title>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <CandidateButton
          onClick={() => onSelect("candidate1")}
          $party="democrat"
        >
          <span role="img" aria-label="Star">
            ⭐
          </span>
          HARRIS
        </CandidateButton>
        <CandidateButton
          onClick={() => onSelect("candidate2")}
          $party="republican"
        >
          <span role="img" aria-label="Eagle">
            🦅
          </span>
          TRUMP
        </CandidateButton>
      </div>
    </Container>
  );
};
