import { useState, useEffect } from "react";

export const useCountAnimation = (targetValue, duration = 1000, steps = 10) => {
  const [currentValue, setCurrentValue] = useState(targetValue);
  const [previousTarget, setPreviousTarget] = useState(targetValue);

  useEffect(() => {
    if (targetValue !== previousTarget) {
      const startValue = currentValue;
      const difference = targetValue - startValue;
      const increment = difference / steps;
      const stepDuration = duration / steps;
      let currentStep = 0;

      const intervalId = setInterval(() => {
        currentStep++;
        if (currentStep >= steps) {
          setCurrentValue(targetValue);
          setPreviousTarget(targetValue);
          clearInterval(intervalId);
        } else {
          setCurrentValue((prev) => prev + increment);
        }
      }, stepDuration);

      return () => clearInterval(intervalId);
    }
  }, [targetValue, previousTarget, currentValue, steps, duration]);

  return Math.round(currentValue);
};
