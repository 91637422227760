// services/shop.js

export const SHOP_ITEMS = {
  volunteer: {
    id: "volunteer",
    name: "Volunteer",
    baseCost: 10,
    baseClicksPerSecond: 0.1,
    description: "A dedicated volunteer making calls (+0.1/sec)",
    icon: "📞",
  },
  campaignOffice: {
    id: "campaignOffice",
    name: "Campaign Office",
    baseCost: 50,
    baseClicksPerSecond: 0.5,
    description: "Local campaign headquarters (+0.5/sec)",
    icon: "🏢",
  },
  socialMedia: {
    id: "socialMedia",
    name: "Social Media",
    baseCost: 200,
    baseClicksPerSecond: 2,
    description: "Social media campaign (+2/sec)",
    icon: "📱",
  },
  tvAd: {
    id: "tvAd",
    name: "TV Advertisement",
    baseCost: 1000,
    baseClicksPerSecond: 10,
    description: "Television ad campaign (+10/sec)",
    icon: "📺",
  },
  celebrity: {
    id: "celebrity",
    name: "Celebrity Endorsement",
    baseCost: 5000,
    baseClicksPerSecond: 50,
    description: "Famous person supporting the campaign (+50/sec)",
    icon: "⭐",
  },
  fraud: {
    id: "fraud",
    name: "Fraud",
    baseCost: 25000,
    baseClicksPerSecond: 1000,
    description: "Steal the election (+1000/sec)",
    icon: "🎲",
  }
};

// Вычисляет стоимость следующей покупки предмета
export const calculateNextCost = (baseCost, owned) => {
  return Math.floor(baseCost * Math.pow(1.15, owned));
};

// Вычисляет общее количество кликов в секунду для всех купленных предметов
export const calculateTotalClicksPerSecond = (ownedItems) => {
  return Object.entries(ownedItems).reduce((total, [itemId, count]) => {
    const item = SHOP_ITEMS[itemId];
    return total + item.baseClicksPerSecond * count;
  }, 0);
};

// Проверяет, достаточно ли у игрока очков для покупки
export const canAffordItem = (itemId, ownedItems, currentPoints) => {
  const item = SHOP_ITEMS[itemId];
  const nextCost = calculateNextCost(item.baseCost, ownedItems[itemId] || 0);
  return currentPoints >= nextCost;
};
