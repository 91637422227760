import React, { useState } from "react";
import {
  Container,
  Title,
  ScoreDisplay,
  ProgressBar,
  Progress,
  VoteButton,
  CandidateScore,
  VoteCount,
  ShareButton,
  ShareVotesButton, // Добавили
  LocalScore, // Добавили
  COLORS,
} from "../styles/StyledComponents";
import { useCountAnimation } from "../hooks/useCountAnimation";
import { CandidateSelection } from "../components/CandidateSelection";
import Shop from "./Shop";

const formatNumber = (num) => {
  return new Intl.NumberFormat("en-US").format(Math.round(num));
};

export const VotingInterface = ({
  scores,
  localScores,
  error,
  handleClick,
  isPressed,
  isLoading,
  selectedCandidate,
  handleCandidateSelect,
  ownedItems,
  handlePurchase,
  totalClicksPerSecond,
  handleShareVotes,
}) => {
  const [showCandidateSelection, setShowCandidateSelection] = useState(false);
  const party = selectedCandidate === "candidate1" ? "democrat" : "republican";

  const animatedScore1 = useCountAnimation(scores.candidate1);
  const animatedScore2 = useCountAnimation(scores.candidate2);
const localScore = localScores[selectedCandidate] || 0;

  const animatedTotal = animatedScore1 + animatedScore2;
  const animatedPercentage = animatedTotal
    ? (animatedScore1 / animatedTotal) * 100
    : 50;

  const handleScoreClick = () => {
    setShowCandidateSelection(true);
  };

  const hasVotesToShare = localScores[selectedCandidate] > 10;

  return (
    <Container>
      <Title>2024 Presidential Election</Title>

      <ScoreDisplay>
        <CandidateScore
          $party="democrat"
          onClick={handleScoreClick}
          style={{ cursor: "pointer" }}
        >
          <div className="name">
            HARRIS{" "}
            <span role="img" aria-label="Star">
              ⭐
            </span>
          </div>
          <div className="votes">{formatNumber(animatedScore1)}</div>
        </CandidateScore>
        <CandidateScore
          $party="republican"
          onClick={handleScoreClick}
          style={{ cursor: "pointer" }}
        >
          <div className="name">
            TRUMP{" "}
            <span role="img" aria-label="Eagle">
              🦅
            </span>
          </div>
          <div className="votes">{formatNumber(animatedScore2)}</div>
        </CandidateScore>
      </ScoreDisplay>

      <ProgressBar>
        <VoteCount className="left">{formatNumber(animatedScore1)}</VoteCount>
        <LocalScore $party={party}>
          Your votes: {formatNumber(localScore)}
          {totalClicksPerSecond > 0 &&
            ` (${totalClicksPerSecond.toFixed(1)}/sec)`}
        </LocalScore>
        <Progress $percentage={100 - animatedPercentage} />
        <VoteCount className="right">{formatNumber(animatedScore2)}</VoteCount>
      </ProgressBar>

      {error && (
        <div
          style={{
            color: COLORS.republican,
            margin: "10px 0",
            fontWeight: "bold",
          }}
        >
          Error: {error}
        </div>
      )}

      <div
        style={{
          marginBottom: 10,
          fontSize: 14,
          color: party === "democrat" ? COLORS.democrat : COLORS.republican,
          fontWeight: 500,
          cursor: "pointer",
        }}
        onClick={handleScoreClick}
      >
        Voting for: {selectedCandidate === "candidate1" ? "HARRIS" : "TRUMP"}
      </div>

      <VoteButton
        onClick={handleClick}
        $pressed={isPressed}
        $party={party}
        disabled={isLoading}
      >
        VOTE
      </VoteButton>

      <ShareVotesButton
        onClick={() => handleShareVotes(selectedCandidate)}
        disabled={!localScores[selectedCandidate]}
        $party={party}
        $pulse={hasVotesToShare}
      >
        Share {formatNumber(Math.floor(localScores[selectedCandidate] || 0))}{" "}
        votes
      </ShareVotesButton>

      <ShareButton
        href="https://t.me/share/url?url=https://t.me/Elections24Bot/vote"
        target="_blank"
        rel="noopener noreferrer"
      >
        SHARE APP
      </ShareButton>

      <div
        style={{
          marginTop: 30,
          fontSize: 14,
          color: COLORS.text,
          fontWeight: 500,
        }}
      >
        Total Votes: {formatNumber(animatedTotal)}
      </div>

      {showCandidateSelection && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowCandidateSelection(false);
            }
          }}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <CandidateSelection
              onSelect={(candidate) => {
                handleCandidateSelect(candidate);
                setShowCandidateSelection(false);
              }}
            />
          </div>
        </div>
      )}

      <Shop
        party={party}
        currentPoints={localScores[selectedCandidate]}
        ownedItems={ownedItems}
        onPurchase={handlePurchase}
        totalClicksPerSecond={totalClicksPerSecond}
      />
    </Container>
  );
};

export default VotingInterface;
