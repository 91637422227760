// utils/telegram.js
export function hapticFeedback(haptic) {
  if (window.Telegram?.WebApp) {
    if (haptic === "selectionChanged") {
      window.Telegram.WebApp.HapticFeedback.selectionChanged();
    } else if (haptic === "light") {
      window.Telegram.WebApp.HapticFeedback.impactOccurred("light");
    } else if (haptic === "medium") {
      window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
    } else if (haptic === "heavy") {
      window.Telegram.WebApp.HapticFeedback.impactOccurred("heavy");
    } else if (haptic === "rigid") {
      window.Telegram.WebApp.HapticFeedback.impactOccurred("rigid");
    } else if (haptic === "soft") {
      window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
    } else if (haptic === "success") {
      window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
    } else if (haptic === "error") {
      window.Telegram.WebApp.HapticFeedback.notificationOccurred("error");
    } else if (haptic === "warning") {
      window.Telegram.WebApp.HapticFeedback.notificationOccurred("warning");
    } else {
      window.Telegram.WebApp.HapticFeedback.selectionChanged();
    }
  }
}

export function initTelegram() {
  if (window.Telegram?.WebApp) {
    window.Telegram.WebApp.setHeaderColor("#ffffff");
    window.Telegram.WebApp.expand();
  }
}
