import UAParser from "ua-parser-js";

export const getUserInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  return {
    browser: `${result.browser.name} ${result.browser.version}`,
    os: `${result.os.name} ${result.os.version}`,
    device: result.device.type || "desktop",
    user_agent: navigator.userAgent,
    additional_info: {
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth,
        pixelRatio: window.devicePixelRatio,
      },
      language: navigator.language,
      languages: navigator.languages,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      platform: navigator.platform,
      cookiesEnabled: navigator.cookieEnabled,
      doNotTrack: navigator.doNotTrack,
      online: navigator.onLine,
      connection: navigator?.connection?.effectiveType,
      vendor: navigator?.vendor,
      windowSize: {
        inner: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        outer: {
          width: window.outerWidth,
          height: window.outerHeight,
        },
      },
    },
  };
};
