import styled from "styled-components";

// Цвета партий
export const COLORS = {
  republican: "#E91D0E", // Приглушенный красный
  democrat: "#0B4FD9", // Приглушенный синий
  republicanLight: "#ff8b83",
  democratLight: "#83a7ff",
  border: "#CCCCCC",
  text: "#333333",
  background: "#FFFFFF",
};

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, sans-serif;
  background: ${COLORS.background};
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${COLORS.text};
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 50px;
  background: ${COLORS.democrat};
  position: relative;
  margin: 30px 0;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: ${COLORS.background};
    z-index: 1;
  }
`;

export const Progress = styled.div`
  width: ${(props) => props.$percentage}%;
  height: 100%;
  background: ${COLORS.republican};
  transition: width 0.3s ease;
  position: relative;
  z-index: 0;
  margin-left: auto; 
`;

export const CandidateButton = styled.button`
  width: 280px;
  height: 120px;
  margin: 10px;
  border: 3px solid
    ${(props) =>
      props.$party === "republican" ? COLORS.republican : COLORS.democrat};
  background: ${(props) =>
    props.$party === "republican"
      ? COLORS.republicanLight
      : COLORS.democratLight}10;
  color: ${(props) =>
    props.$party === "republican" ? COLORS.republican : COLORS.democrat};
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: inherit;

  &:hover {
    background: ${(props) =>
      props.$party === "republican"
        ? COLORS.republicanLight
        : COLORS.democratLight}20;
  }

  span {
    font-size: 48px;
    margin-bottom: 8px;
  }
`;

export const VoteButton = styled.button`
  width: 200px;
  height: 100px;
  border: 3px solid
    ${(props) =>
      props.$party === "republican" ? COLORS.republican : COLORS.democrat};
  background: ${(props) =>
    props.$pressed
      ? props.$party === "republican"
        ? COLORS.republicanLight
        : COLORS.democratLight
      : COLORS.background};
  color: ${(props) =>
    props.$party === "republican" ? COLORS.republican : COLORS.democrat};
  font-size: 24px;
  font-weight: 700;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.1s;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  font-family: inherit;

  &:hover {
    background: ${(props) =>
      props.disabled
        ? COLORS.background
        : props.$party === "republican"
        ? COLORS.republicanLight
        : COLORS.democratLight}20;
  }
`;

export const VoteCount = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 14px;
  font-weight: 500;
  z-index: 2;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }
`;

export const ScoreDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 40px;
`;

export const CandidateScore = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: ${(props) =>
    props.$party === "republican" ? COLORS.republican : COLORS.democrat};

  .name {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .votes {
    font-size: 42px;
  }
`;

export const PercentageMarker = styled.div`
  position: absolute;
  top: -25px;
  left: ${(props) => props.$percentage}%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.text};
`;

export const ShareButton = styled.a`
  display: inline-block;
  min-width: 200px;
  margin-top: 20px;
  padding: 12px 24px;
  background: ${COLORS.background};
  border: 3px solid #000000;
  color: #000000;
  text-decoration: none;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: inherit;

  &:hover {
    background: #0088cc20;
  }
`;

export const ShareVotesButton = styled.button`
  min-width: 200px;
  height: 50px;
  margin: 20px auto 0;
  display: block;
  border: 3px solid /* изменили с 2px на 3px */
    ${(props) =>
      props.$party === "republican" ? COLORS.republican : COLORS.democrat};
  background: transparent;
  color: ${(props) =>
    props.$party === "republican" ? COLORS.republican : COLORS.democrat};
  font-size: 16px;
  font-weight: 600;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  transition: all 0.2s;
  font-family: inherit;

  &:hover {
    background: ${(props) =>
      props.$disabled
        ? "transparent"
        : props.$party === "republican"
        ? COLORS.republicanLight
        : COLORS.democratLight}20;
  }

  animation: ${(props) => (props.$pulse ? "pulse 2s infinite" : "none")};

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0
        ${(props) =>
          props.$party === "republican"
            ? `${COLORS.republican}40`
            : `${COLORS.democrat}40`};
    }
    70% {
      box-shadow: 0 0 0 10px
        ${(props) =>
          props.$party === "republican"
            ? `${COLORS.republican}00`
            : `${COLORS.democrat}00`};
    }
    100% {
      box-shadow: 0 0 0 0
        ${(props) =>
          props.$party === "republican"
            ? `${COLORS.republican}00`
            : `${COLORS.democrat}00`};
    }
  }
`;

export const LocalScore = styled.div`
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  background: ${(props) =>
    props.$party === "republican" ? COLORS.republican : COLORS.democrat};
  color: white;
  padding: 4px 10px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
`;